import { render, staticRenderFns } from "./pit-request-form.vue?vue&type=template&id=b3ec0f96"
import script from "./pit-request-form.vue?vue&type=script&lang=js"
export * from "./pit-request-form.vue?vue&type=script&lang=js"
import style0 from "./pit-request-form.vue?vue&type=style&index=0&id=b3ec0f96&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaveButton: require('/codebuild/output/src3357764168/src/web/components/save-button.vue').default})
