
export default {
    name: 'Card',
    props: {
        title: {
            type: String,
            default: undefined,
        },
    },
};
