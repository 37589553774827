import { render, staticRenderFns } from "./duplicate-transactions-table.vue?vue&type=template&id=b98e30f0"
import script from "./duplicate-transactions-table.vue?vue&type=script&lang=js"
export * from "./duplicate-transactions-table.vue?vue&type=script&lang=js"
import style0 from "./duplicate-transactions-table.vue?vue&type=style&index=0&id=b98e30f0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableActions: require('/codebuild/output/src3357764168/src/web/components/table-actions.vue').default})
